
.banner-text {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}

.projects-navbar {
  height: 6rem;
  background: linear-gradient(to bottom, #171616, #464444);
}

.banner-text ul {
    height: 50px;
    display: flex;
    justify-content: center;
}
.banner-text ul li {
    display: inline-block;
    padding: 40px 15px;
}
.banner-text ul li a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    padding: 5px 10px;
}
.banner-text ul li a:hover {
    border-bottom: solid 3px white; 
    transition: all 0.2s ease-out;
    /*    background: #fff;
    color: #000;*/
}

@media all and (max-width: 960px) {
    .banner-text {
        display: none;
    }
  }