.section-about {
    background-color: #F5F5F5;
    width: 100%;
    max-width: 100%;
    height: auto;
    color: rgb(110, 110, 110);
    display: flex;
    justify-content: space-between;
    gap: 10rem;
    align-items: center;
    padding: 6rem 15%;
}

.section-about-content-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-wrapper {
    position: relative;
    width: fit-content;
}

.img-wrapper {
    width: 300px;
    height: 380px;
    position: relative;
    z-index: 2;
}

.img-wrapper2 {
    width: auto;
    height: 400px;
    position: absolute;
    bottom: -2.5rem;
    left: -4rem;
    z-index: 1;
}

.img-wrapper img, .img-wrapper2 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.section-about h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    letter-spacing: 2px;
    color: #000;
    margin: 0;
    padding: 0;
}

.header-section-content h1::after {
    content: '';
    display: block;
    width: 12%;
    height: 3px; 
    background-color: #0292b7; 
    margin: 10px 0 20px 0;
}

.section-about .paragraphs {
    font-weight: 300;
    text-align: justify;
}

.section-about p {
    letter-spacing: 0.5px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 0.5rem;
}

.section-about p:last-child {
    margin-bottom: 0;
}

.section-about span {
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-weight: 300;
}

.header-section-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.linkedin-wrapper {
    margin: 0;
    padding: 0;
    color: rgb(110, 110, 110);
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
    text-align: center;
    height: 3rem;
}

.linkedin-wrapper a {
    color: #0292b7;
    margin-left: 0.3rem;
}

.linkedin-wrapper a:hover {
    color: #08c5f5;
}

@media all and (max-width: 960px) {
    .section-about {
        height: auto;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
        width: 100%;
        text-align: center;
    }

    .section-about .paragraphs {
        max-width: 95%;
        margin-bottom: 1.5rem;
        text-align: center;
        width: 100%;
    }

    .section-about .paragraphs p {
        font-size: 16px;
        margin: 0;
        width: 100%;
    }

    .section-about span {
        padding: 2.5rem 0 2rem !important;
    }

    .section-about img {
        margin-top: 0;
    }

    .img-wrapper {
        width: 250px;
        height: 310px;
        margin: 0 auto;
    }

    .img-wrapper2 {
        bottom: -2rem;
        left: -3rem; 
        right: 0;
        height: 330px;
        margin: 0 auto; 
    }
    .section-about-content-2 {
        justify-content: center !important;
        align-items: center !important;
    }
    .header-section-content h1::after {
        margin: 10px auto 20px auto;
        width: 25px;
    }
    .section-about .paragraphs p {
        margin-bottom: 0.5rem;
    }

    .section-about .paragraphs p:last-child {
        margin-bottom: 0rem;
    }
}

@media all and (max-width: 600px) {
    .header-section-content h1::after {
        margin: 10px auto 20px auto;
        width: 25px;
    }

    .section-about-content-2 {
        justify-content: center !important;
        align-items: center !important;
    }
    .section-about {
        padding: 4rem 10%;
        text-align: center;
    }

    .section-about .paragraphs {
        text-align: center;
        margin: 0;
    }

    .img-wrapper {
        width: 200px;
        height: 260px;
        margin: 0 auto; 
    }

    .img-wrapper2 {
        bottom: -2rem;
        left: -3rem;
        right: 0;
        height: 280px;
        margin: 0 auto;
    }

    .section-about h1 {
        font-size: 22px;
        text-align: center;
    }

    .section-about .paragraphs p {
        font-size: 14px;
        text-align: center;
        margin: 0;
    }

    .section-about .paragraphs p {
        margin-bottom: 0.5rem;
    }

    .section-about .paragraphs p:last-child {
        margin-bottom: 0rem;
    }
}

