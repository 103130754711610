.project {
  height: auto;
  padding: 35px 0;
  position: relative;
  background-color: #f1f5f8;
}

.project h2 {
  font-family: 'Poppins';
  font-size: 26px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

.projects-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

.projects-wrapper-1 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.projects-wrapper-1 .card-content {
  position: absolute;
  top: 50%;
  right: -60px;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 2;
  width: 90%;
  height: auto;
}

.projects-wrapper-2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.projects-wrapper-2 .card-content {
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 2;
  width: 90%;
  height: auto;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 15px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.icon-btn:hover {
  color: #0292b7;
}

.view-more-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.fancy-outline-btn {
  border: 2px solid #0292b7;
  color: #0292b7;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0;
  background-color: transparent;
  transition: all 0.3s ease;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fancy-outline-btn:hover {
  background-color: #0292b7;
  color: white;
  border-color: #0292b7;
}

@media (max-width: 1080px) {
  .card-content {
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    margin-top: 20px;
    height: 320px !important;
  }

  .projects-wrapper-1 .card-content {
    top: 25%;
    left: 17% !important;
    transform: translateY(0);
  }

  .projects-wrapper-2 .card-content {
    top: 25%;
    right: 5%;
    transform: translateY(0);
  }

  .projects-wrapper {
    gap: 4rem;
  }

  .view-more-container {
    margin-top: 5rem;
  }

}

@media (max-width: 768px) {
  .project {
    padding-bottom: 5rem;
  }

  .projects-wrapper-1,
  .projects-wrapper-2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card-content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    margin-top: 20px;
  }

  .projects-wrapper-1 .card-content {
    top: 25%;
    left: 5%;
    transform: translateY(0);
    padding: 10px;
  }

  .projects-wrapper-2 .card-content {
    top: 25%;
    right: 10%;
    transform: translateY(0);
    padding: 10px;
  }

  .projects-wrapper-1 .project-card,
  .projects-wrapper-2 .project-card {
    width: 90%;
  }

  .projects-wrapper-1 .project-card .tag,
  .projects-wrapper-2 .project-card .tag {
    font-size: 0.65rem;
    text-transform: none;
    padding: 5px 8px;
  }

  .projects-wrapper-1 .project-card .card-description {
    margin-bottom: 5px;
  }
}

@media (max-width: 620px) {

  .card-title {
    font-size: 1em !important;
  }

  .projects-wrapper-1 .card-content {
    top: 10%;
    left: 10%;
    transform: none;
  }

  .projects-wrapper-2 .card-content {
    top: 10%;
    left: -8%;
    transform: none;
  }

  .projects-wrapper {
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .project {
    padding-bottom: 5rem;
  }

  .project h2 {
    font-size: 22px;
  }

  .card-content {
    padding: 15px;
    max-width: 100%;
  }

  .card-title {
    font-size: 0.8em !important;
  }

  .card-description {
    font-size: 0.75em !important;
  }

  .card-footer {
    flex-direction: column;
  }

  .projects-wrapper-1 .card-content {
    top: 0;
    left: 20%;
    transform: none;
  }

  .projects-wrapper-2 .card-content {
    top: 0;
    left: -8%;
    transform: none;
  }

  .projects-wrapper {
    gap: 2rem;
  }
}

@media (max-width: 405px) {
  .project {
    padding-bottom: 6rem;
  }

  .card-title {
    font-size: 0.8em !important;
  }
}