#section05 {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#section05 a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 2px;
  font-family: "Poppins";
  font-weight: 300;
  text-transform: uppercase;
}

#section05 a span {
  position: absolute;
  left: 50%;
  width: 24px;
  height: 24px;
  bottom: -1.5rem;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
}

#section05:hover {
  transition: 0.2s ease-in-out;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
