.skills-list {
    list-style-type: none;
    margin-left: 0 !important;
    padding: 0 !important;
}

.skills {
    width: 30%;
}

.skills-header {
    text-align: center;
}

.element-p {
    margin: 1rem 0;
}

.skills ul li label {
    display: block;
    float: left;
}

.skills ul li .progress-container {
    position: relative;
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
}

.skills ul li .progress-container progress {
    width: 100%;
    height: 10px;
    border-radius: 12px;
    background-color: lightgray;
}

.skills ul li .progress-container progress::-webkit-progress-value {
    background: #0292b7;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}

.skills ul li .progress-container progress::-webkit-progress-bar {
    background: lightgray;
    border-radius: 12px;
}

.skills ul li .progress-circle {
    position: absolute;
    top: 75%;
    transform: translate(-50%, -50%);
    height: 14px;
    width: 14px;
    background-color: #0292b7;
    border-radius: 50%;
}


@media all and (max-width: 960px) {
    .skills {
        width: 90%;
        margin: 0 5%;
    }
}
