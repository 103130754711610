.stats-wrapper {
    padding: 2rem;
    padding-top: 0;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.stats-header {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #454545;
    font-size: 22px;
    text-align: center;
    margin-bottom: 1.5rem;
}

.stats-wrapper-item {
    font-family: "Montserrat", sans-serif;
    border-top: solid 1px #f4eded !important;
    border-right: solid 1px #f4eded !important;
    padding-top: 3rem;
    padding-bottom: 2%;
    width: 17%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.stats-wrapper-item svg {
    color: #0292b7;
    width: 40px;
    height: 40px;
}

.stats-wrapper-item span {
    text-align: center;
    width: 50%;
}

.stats-wrapper-item:last-child {
    border-right: none !important;
}

@media (max-width: 768px) {
    .stats-wrapper-item {
        width: 45%;
        margin-bottom: 1rem;
    }

    .stats-wrapper-item:nth-child(2), .stats-wrapper-item:nth-child(4) {
        border-right: none !important;
    }
}

@media (max-width: 480px) {
    .stats-wrapper-item {
        width: 50%;
        border-right: none;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .stats-wrapper-item svg {
        width: 30px;
        height: 30px;
    }

    .stats-wrapper-item span {
        font-size: 14px; 
        width: 70%;
    }
}
