.timeline {
    overflow-x: hidden;
    padding-bottom: 4rem;
}

.timeline h3 {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: normal;
    padding-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.wrapper {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 20px;
    position: relative;
}

.wrapper .center-line {
    position: absolute;
    height: 100%;
    width: 2px;
    background: rgb(246, 236, 236);
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.wrapper .row {
    display: flex;
}

.wrapper .row-1 {
    justify-content: flex-start;
}

.wrapper .row-2 {
    justify-content: flex-end;
}

.wrapper .row section {
    background: #fff;
    width: calc(50% - 40px);
    padding: 20px;
    position: relative;
    border-top: solid 3px #0292b7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wrapper .row section::before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    top: 28px;
    z-index: -1;
    transform: rotate(45deg);
}

.row span {
    display: inline-block;
}

.row img {
    float: right;
    width: 60px;
    height: auto;
}

.row-1 section::before {
    right: -7px;
}

.row-2 section::before {
    left: -7px;
}

.row section .icon {
    position: absolute;
    background: #fff;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    color: #0292b7;
    border: solid 2px #0292b7;
    box-shadow: none;
    content: none;

}

.center-line .scroll-icon {
    position: absolute;
    background: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 1.3rem;
    border-radius: 50%;
    color: #0292b7;
    border: solid 2px #0292b7;
}

.center-line .scroll-icon {
    bottom: -40px;
    left: 50%;
    font-size: 25px;
    transform: translateX(-50%);
}

.row-1 section .icon {
    top: 15px;
    right: -50px;
    font-size: 0;
}

.row-2 section .icon {
    top: 15px;
    left: -50px;
    font-size: 0;
}

.details .date {
    color: rgb(110, 110, 110);
    font-size: 0.85em;
}

.row section .bottom span {
    font-size: 0.85em;
}

.row section .details {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.row section .details,
.row section .bottom {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.row section .details .title {
    font-family: "Montserrat", sans-serif;
    color: #0292b7;
    font-size: 16px;
    font-weight: 600;
}

.row section p {
    font-family: "Montserrat", sans-serif;
    margin: 10px 0 17px 0;
}

.row section .bottom a {
    text-decoration: none;
    transition: all 0.3s ease;
}

.row section .bottom a:hover {
    opacity: 0.85;
    transform: scale(0.97);
}

.tech-stack {
    padding: 10px 0 10px 0;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    font-size: 15px;
    /* allows horizontal scroll */
    white-space: nowrap;
    /* prevents wrapping to the next line */
}

.tech-stack-item {
    font-family: "Montserrat", sans-serif;
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #f4f4f4;
    display: inline-block;
}

@media all and (max-width: 960px) {
    .wrapper .center-line {
        left: 40px;
    }

    .wrapper .row {
        margin: 30px 0 3px 60px;
    }

    .wrapper .row section {
        width: 100%;
    }

    .row-1 section::before {
        left: -7px;
    }

    .row-1 section .icon {
        left: -50px;
    }
}

@media (max-width: 468px) {
    .row section .details .title {
        font-size: 14px;
    }

    .details .date {
        font-size: 0.75em;
    }

    .row section p {
        font-size: 13px;
    }

    .row-1 section .icon {
        left: -50px !important;
    }

    .tech-stack {
        flex-wrap: nowrap;
    }

    .tech-stack-item {
        font-size: 0.8em;
    }
}