.download-button {
    font-size: 20px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;    
    background-color: white;
    padding: 0.6rem 1rem;
    color: rgb(122, 122, 122);
    border: solid 1px #ccc;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    outline: none;
}

.download-button-link {
    text-decoration: none;
    outline: none;
}

.download-button:hover {
    background-color: gray;
    cursor: pointer;
    color: white;
    transition: all 0.4s ease-in-out;
}

@media all and (max-width: 960px) { 
    .download-button {
        width: 13rem;
        padding: 0.5rem 1rem;
        font-size: 18px;
    }
}