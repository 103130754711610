.intro-text {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Raleway", sans-serif;
}
.intro-text h2 { 
    text-align: center;
    color: #fff;
    font-size: 50px;
    letter-spacing: 5px;
}
.intro-text p {
    text-align: center;
    font-size: 24px;
    width: 100%;
    margin: auto;
    color: #fff;
    line-height: 1.7;
}