.footer{
    height: auto;
    background-color: #ECECEC;
}

.footer h2 {
    font-family: Poppins;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

.footer ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.7rem;
}

.icons {
    list-style-type: none;
    text-align: center;
}

.icons i {
    font-size: 2.5em;
    margin-bottom: 1rem;
}

.footer ul li a {
    color: lightgray;
}

.icons li a{
    text-decoration: none;
}

.icons li a p{
    display: block;
}

.icons li a p {
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}

.footer span {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.5rem;
    color: #494040;
}