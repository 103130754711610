.navbar-icon {
  z-index: 10;
  position: fixed;
  top: 40px;
  left: 25px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.navbar-icon i {
  color: gray;
  font-size: 25px;
}

.navbar-icon i:hover {
  cursor: pointer;
}

.navbar-scroll {
  top: 0;
  left: 0;
  position: fixed;
  width: 14rem;
  height: 100vh;
  background-color: #ccc;
  z-index: 10;
}

.navbar-scroll ul {
  z-index: 10;
  margin-top: 6rem;
  margin-left: 1rem;
}

.navbar-scroll ul li {
  z-index: 11;
  list-style-type: none;
  text-transform: uppercase;
  margin-top: 2rem;
}

.navbar-scroll ul li a {
  text-decoration: none;
  color: black;
}

.navbar-scroll ul li a:hover {
  color: rgb(136, 112, 112);
  transition: all 0.17s ease-in-out;
}

@media all and (max-width: 960px) {
  .navbar-icon {
    left: 15px;
    top: 25px;
  }
}
