* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Ubuntu', sans-serif;
    overflow-x: hidden;
}

a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background: #fff;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(145, 131, 131);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}