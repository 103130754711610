.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-image {
  border-radius: 15px;
  display: flex;
  align-items: flex-end;
  padding: 0 2rem;
  height: 24rem;
  background: linear-gradient(135deg, #0292b7, #a7e7f7, #ffffff);
  width: 100%;
  max-width: 700px;
  z-index: 0;
  opacity: 0.7;
}

.card-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.card-image:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 2;
  width: 100%;
  max-width: 650px;
  height: 320px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.card-title {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-description {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  font-family: "Montserrat", sans-serif;
  background-color: #f1f1f1;
  color: #555;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.project-card-icons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.icon-btn, .btn-github {
  color: #000;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.btn-github {
  cursor: pointer;
  font-size: 1.7rem !important;
}

.icon-btn:hover, .btn-github :hover {
  color: #0292b7;
  transition: 0.12s color ease-in-out;
}

.view-more-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.view-more-link:hover {
  text-decoration: underline;
}

@media (max-width: 1080px) {
  .card-image {
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .card-content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    transform: none;
    max-width: 100%;
    margin-top: 20px;
  }

  .card-image {
    width: 100%;
    height: 22rem;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .card-content {
    height: 200px;
    padding: 15px;
    max-width: 100%;
  }

  .card-title {
    font-size: 0.8em !important;
  }

  .card-description {
    font-size: 0.8rem;
  }

  .card-footer {
    flex-direction: column;
  }

  .card-image {
    height: 18rem;
    align-items: flex-start;
  }
}
